.navBar {
  background-color: #b8ab4b;
}

.swiper-pagination {
  position: static;
}

.swiper,
.swiper-port {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.swiper-pagination-bullet {
  background-color: wheat;
  width: 0.5rem;
  height: 0.5rem;
  margin-left: 0.5rem;
}

.swiper-slide {
  display: flex;
  flex-wrap: wrap;
  /* padding: 2.2rem; */
  padding-top: 0;
  line-height: 1rem;
  text-align: center;
  font-size: 12px;
  color: rgb(46, 46, 46);
  height: 7rem;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.aside-icon {
  font-family: Arial, Helvetica, sans-serif;
  /* font-style: italic; */
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap-reverse;
  max-width: 12rem;
  color: white;
  font-weight: 200 !important;
}

.swiper-button-prev {
  color: #f0f0f0;
}

.swiper-button-next {
  color: #254a65;
}

.circleBase {
  border-radius: 50%;
}

.circle {
  margin-top: 1rem;
  width: 100px;
  height: 100px;
  background: #4bc475;
  border: 1px solid #000;
}

.circle-img1,
.circle-img1-a,
.circle-img2,
.circle-img2-a,
.circle-img3,
.circle-img3-a,
.circle-img4,
.circle-img4-a,
.circle-img5,
.circle-img5-a,
.circle-img6,
.circle-img6-a {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100px;
  height: 100px;
  border: 1px solid #000;
}

.circle-img1,
.circle-img1-a {
  background: url("../../images/daniel.jpg");
  background-size: contain;
}

.circle-img2,
.circle-img2-a {
  background: url("../../images/mariam.jpg");
  background-size: contain;
}

.circle-img3,
.circle-img3-a {
  background: url("../../images/HeartSongLogo.png");
  background-size: cover;
  background-color: white;
}

.circle-img4,
.circle-img4-a {
  background: url("../../images/dright.jpg");
  background-size: cover;
  background-color: white;
}

.circle-img5,
.circle-img5-a {
  background: url("../../images/htaylor.jpg");
  background-size: cover;
  background-color: white;
}

.circle-img6,
.circle-img6-a {
  background: url("../../images/emile.jpg");
  background-size: cover;
  background-color: white;
}

a {
  text-decoration: none;
  color: #e2e2e2;
}

a:hover {
  color: #162336;
}

a:active {
  color: #162336;
}

.material-symbols-outlined {
  color: #ffffff;
  font-size: 5rem;
}

.navbar-toggler-icon {
  width: 37.5px !important;
  height: 37px !important;
}

.circle-img1-a,
.circle-img2-a,
.circle-img3-a,
.circle-img4-a,
.circle-img5-a,
.circle-img6-a {
  display: none;
}

/* for headerportfolio slider */
.headport-section {
  margin-top: -0.5rem;
}

.headerImage,
.headerImage2 {
  display: block;
  width: 98%;
  height: 30vh;
  background: url("../../images/NewbreedSlide.jpg") no-repeat;
  background-size: contain;
}

.headerImage2 {
  /* background: url("../../images/PowerDesignSlideCroped.jpg") no-repeat; */
  background-size: contain;
}

.headerImage-a {
  display: none;
}

@media screen and (min-width: 280px) {

  .circle-img1,
  .circle-img2,
  .circle-img3,
  .circle-img4,
  .circle-img5,
  .circle-img6 {
    margin-top: 0.5rem;
  }
}

@media screen and (min-width: 320px) {
  .aside-icon {
    max-width: 13rem;
  }
}

@media screen and (min-width: 360px) {
  .aside-icon {
    max-width: 15rem;
  }
}

@media screen and (min-width: 375px) {

  .headerImage,
  .headerImage2 {
    background-size: cover;
    height: 33vh;
    background-position-x: -3rem;
    width: 100%;
  }
}

@media screen and (min-width: 414px) {

  .headerImage,
  .headerImage2 {
    width: 98%;
  }
}

@media screen and (min-width: 480px) {
  .aside-icon {
    max-width: 17rem;
  }
}

@media screen and (min-width: 568px) {

  .headerImage,
  .headerImage2 {
    margin-left: 1rem;
  }
}

@media screen and (min-width: 600px) {
  .headerImage {
    height: 37.5vh;
    background-position-x: -3rem;
  }

  .navBar {
    background-color: white;
  }
}

@media screen and (min-width: 768px) {
  .swiper-port {
    display: none;
  }

  a {
    text-decoration: none;
    /* color: #162336; */
  }

  a:hover {
    color: #162336;
  }

  a:active {
    color: #162336;
  }

  .circle-img1,
  .circle-img2,
  .circle-img3,
  .circle-img4,
  .circle-img5,
  .circle-img6 {
    display: none;
  }

  .circle-img1-a,
  .circle-img2-a,
  .circle-img3-a,
  .circle-img4-a,
  .circle-img5-a,
  .circle-img6-a {
    display: block;
    width: 150px;
    height: 150px;
    margin-right: 1rem;
  }

  .aside-icon {
    font-size: 1rem;
    line-height: 1rem;
    max-width: 25rem;
    font-family: "Times New Roman", Times, serif;
    font-weight: 100;
    font-style: italic;
    color: white;
  }
}

@media screen and (min-width: 1024px) {
  .swiper-port {
    margin-left: 3rem;
  }

  .bg-light {
    width: 100vw;
  }
}

@media screen and (min-width: 1134px) {

  .headerImage,
  .headerImage2 {
    height: 44vh;
    width: 74vw;
  }
}

@media screen and (min-width: 1250px) {
  .btn-light {
    background-color: #bababaca !important;
    box-shadow: #f3f3f3e0 0px -50px 36px -29px inset;
    color: #574c41;
  }
}

@media screen and (min-width: 1500px) {

  .headerImage,
  .headerImage2 {
    background-size: contain;
    width: 70%;
  }

  .btn-light {
    background-color: #dcdcd4 !important;
  }
}