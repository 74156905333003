.priceContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.priceCardTop {
    margin-bottom: 2vw;
}

.h2Title {
    font-size: 10vw;
    margin: 0 0 0 3.5vw;
    padding: 12vw 0 0 0;
    color: white;
}

.PriceTitle {
    font-size: 7vw;
    text-transform: uppercase;
    font-weight: 800;
}

.price {
    font-size: 15vw;
}

.subTitle {
    font-size: 4vw;
    font-weight: 800;
    text-transform: uppercase;
}

div b {
    font-size: 10vw;
}

li {
    font-size: 4vw;
    font-weight: normal;

}

.background {
    background-image: url(../../images/footer-background-desktop.jpg);

}

.hrStyle {
    display: block;
}

@media screen and (min-width: 728px) {
    .priceContainerTop {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100vw;

    }

    .priceContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        width: 100vw;
        margin: 0 2vw;
    }



    .subTitle {
        font-size: 2.5vw;
        text-transform: uppercase;
        font-weight: 800;
        line-height: 5vw;
    }

    .PriceTitle {
        font-size: 5vw;
    }

    .price {
        font-size: 10vw;
    }

    div b {
        font-size: 8vw;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .priceCard,
    .priceCardTop {
        width: 45vw;
        margin: 2vw 0 0 0;
    }

    .priceCardTop {
        height: 30vw;
    }

    .listGroup {
        display: flex;
        flex-direction: column;
        align-items: start;
        border: none !important;
        font-size: 4vw;
        font-weight: normal;
    }

    .domain {
        font-size: 2.5vw;
        text-transform: uppercase;
        font-weight: 800;
        line-height: 5vw;
    }

}

@media screen and (min-width: 728px) {

    .subTitle,
    .PriceTitle {
        font-size: 1rem;
        line-height: 1rem;
    }

    .priceCardTop {
        height: 8rem;
    }

    .priceContainer {
        align-items: start;
    }

    .price {
        font-size: 4rem;
    }

    .h2Title {
        padding: 2rem 0 0 0;
    }

    div b {
        font-size: 2rem;
        line-height: 2rem;
    }

    li, .domain, .listGroup {
        font-size: .9rem;

    }

    .domain {
        font-size: 1rem;
        line-height: 1.2rem;
    }

    .hrStyle {
        display: none;
    }
}

@media screen and (min-width: 1024px) {

    .h2Title {
        font-size: 3rem;
        margin: 0 0 0 1rem;
    }

    /* div b {
        font-size: 1.5rem;
    } */

    /* section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin: -32px 1rem 0 1rem;
    } */

    .priceContainerTop {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        align-items: start;
        justify-content: start;
        width: 62rem;
    }

    .priceContainer {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: start;
        justify-content: start;
        width: 62rem;
        margin: 0;
    }

    .priceCardTop {
        height: 9rem;
    }

}