@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

.styles {
  font-family: sans-serif;
  text-align: center;
  /* margin-bottom: 1rem; */
}

.title {
  border-radius: 0.5rem;
  font-size: 10.2vw;
  line-height: 18.5vw;
  padding: 3vw 3vw 0px 3vw;
  letter-spacing: -1px;
}

.servicesText {
  font-family: Anton;
  font-size: 5.1vw;
  padding: 0 0 47vw 0;
  text-transform: capitalize;
  line-height: 5.8vw;
  /* background-image: linear-gradient(180deg, #d7c96d00 0%, #cfb92b8f -21%, #0000 51%); */
  height: 130vw;
  letter-spacing: -1px;
}

.servicesText h1 {
  color: #b8ab4b;
}

.btnHeader {
  font-size: 3vw;
  font-weight: bold;
  letter-spacing: normal;
  padding: .8rem 0.2rem;
  /* margin: 1rem 0 1.7rem 0; */
  font-family: sans-serif;
  background-color: #0b1f42;
  color: white;
  filter: none;
  border-color: white;
  position: relative;
  z-index: 1;
  top: -5rem;
  width: 50%;
  filter: drop-shadow(2px 12px 13px rgb(255, 255, 255));
}

.btnHeader:hover {
  background-color: #56389c !important;
  transition: 0.5s;
}

.titleWrapper {
  color: black;
  background-color: #f0f0f0;
}


@media screen and (min-width: 600px) {
  .newBreedImg {
    display: none;
  }

  .btnHeader {
    position: relative;
    z-index: 1;
    top: 100px;
    width: 30%;
    padding: .3rem;
  }

  .servicesText {
    font-size: 3.1vw;
    display: none;
  }

  .servicesText h1 {
    font-size: 4.1vw;
    line-height: 4.8vw;
    display: none;
  }

  .headerImg {
    display: none;
  }

  .titleWrapper::before {
    content: "Designing Glory LLC";
    font-family: Anton;
    display: block;
    color: #f0f0f0;
    text-shadow: #1a181d 1px 1px 1px;
    height: 12vw;
    border-radius: 0.5rem;
    font-size: 8vw;
    letter-spacing: -1px;
  }

  .titleWrapper::after {
    content: "Professional custom websites";
    font-family: Anton;
    display: block;
    height: 6vw;
    /* background-color: #f0f0f096; */
    color: #f0f0f0;
    text-shadow: #1a181d 1px 1px 1px;
    border-radius: 0.5rem;
    font-size: 4vw;
    top: -32px;
    position: relative;
  }


  .titleWrapper {
    padding-top: 10vw;
    background-position: center;
    background: url("../../images/new-desktop-dark.jpg") no-repeat;
    background-size: cover;
    height: 55vw;
    position: relative;
  }

}

@media screen and (min-width: 1024px) {

  .btnHeader {
    font-size: 1.5vw;
    padding: .5rem;
    width: 20%;
  }

  .titleWrapper::after {
    height: 3vw;
    top: -60px;
  }

}

@media screen and (min-width: 1500px) {
  .title {
    font-size: 118.2px;
    line-height: 186.5px;
    top: -229px;
    height: 235px;
    padding: 47px;
  }

  .titleWrapper::after {
    height: 3vw;
    top: -100px;
  }

  .servicesText h1 {
    font-size: 48.8px;
    line-height: 45.8px;
    top: -220px;
    color: black;
  }

  .btnHeader {
    font-size: 34.3px;
    top: 100px
  }

}


@media screen and (min-width: 2000px) {


  .titleWrapper::before {
    font-size: 180px;
    line-height: 186.5px;
  }

  .btnHeader {
    font-size: 34.3px;
    top: 170px;
    /* width: 100%; */

  }

}


@media screen and (min-width: 2300px) {


  .titleWrapper::before {
    font-size: 180px;
    line-height: 286.5px;
  }

}

@media screen and (min-width: 2400px) {


  .titleWrapper::after {
    font-size: 98.8px;
    line-height: 95.8px;
  }

}

@media screen and (min-width: 2800px) {

  .titleWrapper::after {
    font-size: 98.8px;
    line-height: 0;
  }

}